import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "alert-list"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  key: 0,
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "alert",
  "aria-label": "Close"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.announcements, _ref => {
    let [id, announcement] = _ref;
    return _openBlock(), _createElementBlock("div", {
      key: id,
      class: _normalizeClass('alert alert-' + announcement.variant + (announcement.dismissible ? ' alert-dismissible' : ''))
    }, [_createCommentVNode(" eslint-disable-next-line vue/no-v-html -- Announcements are admin generated content "), _createElementVNode("h2", {
      innerHTML: announcement.title
    }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("p", {
      innerHTML: announcement.text
    }, null, 8 /* PROPS */, _hoisted_3), announcement.dismissible ? (_openBlock(), _createElementBlock("button", _hoisted_4)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.messages, message => {
    return _openBlock(), _createElementBlock("div", {
      key: message.text,
      class: _normalizeClass('alert alert-dismissible alert-' + message.variant),
      role: "alert"
    }, [_createElementVNode("p", null, _toDisplayString(message.text), 1 /* TEXT */), _cache[0] || (_cache[0] = _createElementVNode("button", {
      type: "button",
      class: "btn-close",
      "data-bs-dismiss": "alert",
      "aria-label": "Close"
    }, null, -1 /* HOISTED */))], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]);
}